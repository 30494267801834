<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-bind -->
<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="mb-2 rounded"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'tab-bg' :'bg-white'"
      >

        <b-tabs
          v-model="tabIndex"
          :class="$store.state.appConfig.layout.skin === 'dark' ? 'custom-tab-bg-blue' :'custom-tab-bg-aliceblue'"
          content-class="col-12 col-md-12 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-12 col-12"
          nav-class="nav-left"
          class="row document-tab"
        >
          <!-- <b-tab>
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold default">{{ $t('overview') }}</span>
            </template>
            <Overview @tabChanger="tabChanger" />
          </b-tab> -->
          <b-tab>
            <template
              #title
            >
              <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
                <feather-icon
                  icon="UserIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold default">{{ $t('general') }}</span>
              </div>
            </template>

          </b-tab>
          <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">{{ $t('residency') }}</span>
        </template>
        <Residency @tabChanger="tabChanger" />
      </b-tab> -->
          <b-tab>
            <!-- title -->
            <template #title>
              <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
                <feather-icon
                  icon="LinkIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold bank-accounts">{{ $t('bank_accounts') }}</span>
              </div>
            </template>

          </b-tab>
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon
                icon="LinkIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold legal-companies">{{ $t('legal_companies') }}</span>
            </template>

          </b-tab>
        </b-tabs>
      </div>
      <General
        v-if="$route.params.tab==0"
      />
      <bank-accounts v-if="$route.params.tab==1" />
      <legal-companies v-if="$route.params.tab==2" />
    </b-overlay>
  </div>
</template>
<script>
import {
  BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
// import Overview from './Overview.vue'
import General from './General.vue'
// import Residency from './Residency.vue'
import BankAccounts from './BankAccounts.vue'
import LegalCompanies from './LegalCompanies.vue'
import { codeSpinner } from '@/views/components/overlay/code'
import tabsMacher from '@/mixins/tabsMacher2'
// import tour from '@/mixins/tour'

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    // Overview,
    General,
    // Residency,
    BankAccounts,
    LegalCompanies,
  },

  mixins: [tabsMacher,
  //  tour
  ],
  data() {
    return {
      codeSpinner,
      show: true,
    }
  },
  async created() {
    this.show = false
  },
  updated() {
    this.show = false
  },
  methods: {
    tabChanger(val) {
      this.tabIndex = val
    },
    // startTour(id) {
    //   console.log(id)
    //   this.tour.show([id])
    // },
  },
}
</script>
<style lang="scss">
.custom-tab-bg-aliceblue .nav-pills .nav-link.active{
  background-color: $primary !important;
}
.custom-tab-bg-aliceblue .nav-pills .nav-link{
  background-color: aliceblue  ;
}
.custom-tab-bg-blue .nav-pills .nav-link{
  background-color: rgb(155, 190, 221);
}
[dir] .tabs .nav-pills .nav-link{
  margin: 3px !important;
}
// .tab-bg{
//   background-color: #172238 ;
// }
</style>
