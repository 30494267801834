export default {
  data() {
    return {
      tabIndex: +this.$route.params.tab || 0,
    }
  },
  computed: {
    tabRoute() {
      return this.$route.params.tab
    },
  },

  watch: {
    tabIndex(val) {
      if (this.$route.params.tab !== val) {
        this.$router.replace({ params: { ...this.$route.params, tab: val } })
      }
    },
    tabRoute(val) {
      this.tabIndex = val
    },
  },

  created() {
    if (!this.$route.params.tab) {
      this.$router.push({ params: { ...this.$route.params, tab: this.tabIndex } })
    }
  },
}
