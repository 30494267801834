<template>
  <b-row>
    <b-col
      sm="12"
    >
      <b-col>
        <b-alert
          :variant="status === 'pending'? 'warning' : status === 'success' ? 'success' : 'danger' "
          show
          class="px-1"
        >

          <h4 class="alert-heading d-flex justify-content-between">

            <div style="margin:5px 0">
              <span>{{ $t('date') }}</span>
              <span> : </span>
              <span>{{ date }}</span>
            </div>
            <div style="margin:5px 0">
              {{ $t(status) }}
            </div>
          </h4>
          <div class="alert-body">
            <p>{{ message }}</p>
          </div>
        </b-alert>

      </b-col>

    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { alphanumeric } from '@/mixins/alphanumeric'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  props: {
    status: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },

}
</script>
