import axiosIns from '@/libs/axios'

export default class legalApis {
getDocumentLegalInfo = async () => await axiosIns.get('auth/document/legal')

    saveDocumentLegal= async (data, streamFunction) => {
      const formData = new FormData()
      Object.keys(data).forEach(element => {
        if (Array.isArray(data[element]) && data[element].length > 0) {
          data[element].forEach((image, index) => formData.append('file_proof[]', image))
        } else {
          formData.append(element, data[element])
        }
      })
      return await axiosIns.post('auth/document/legal/action', formData, {
        onUploadProgress: e => {
          if (streamFunction) {
            streamFunction(e)
          }
        },
      })
    }

    getCompanyTypes = async () => await axiosIns.get('auth/document/legal/company-types')
}
